import React from "react";
import { primaryColor } from "../../helpers/colors";

export default function Titulo({ titulo }) {
  return (
    <div className="w-full pt-20 flex justify-center items-center">
      <h1
        className="font-bold text-3xl p-2"
        style={{ color: primaryColor, }}
      >
        {titulo}
      </h1>
    </div>
  );
}
