import React from 'react'
import Logo from "../../assets/images/logo-gentil-branco.png";
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <nav className='flex bg-transparent flex-col lg:flex-row p-10 text-2xl justify-between pl-40 pr-40' >
            <div className='flex justify-center items-center'>
                <Link to="/"><img src={Logo} width="128px" alt="logo" /></Link>

            </div>
            <ul className='flex justify-center mt-5 lg:mt-0 items-center gap-5 text-sm lg:text-lg'>
            <li><a href='#inicio' className='hover:underline hover:text-gray-200'>Início</a></li>
                <li><a href='#sobre' className='hover:underline hover:text-gray-200'>Como participar</a></li>
                <li><a href='#recurso' className='hover:underline hover:text-gray-200'>Nossas Cotações</a></li>
                <li><a href='#novidades' className='hover:underline hover:text-gray-200'>Quem Somos</a></li>
                <li><a href='#precifica' className='hover:underline hover:text-gray-200'>Contato</a></li>
            </ul>
        </nav>
    )
}
