import React, { useEffect } from "react";
import { secondColor } from "../../helpers/colors";
import Item from "./components/Item";
import recursoAnimation from "../../assets/lottie/lista-cotacao.json";
import recursoAnimation2 from "../../assets/lottie/como-funciona.json";
import Lottie from "react-lottie";
import Titulo from "../Titulo";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faChartPie, faGear, faPencil, faTag, faUser } from '@fortawesome/free-solid-svg-icons'
import { primaryColor } from "../../helpers/colors";

export default function Recurso() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: recursoAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: recursoAnimation2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="recurso">
      <div
        className="pr-20 pl-20 pb-20 bg-[#F7F1FE]"
        style={{  minHeight: "500px" }}
      >
        <div className="w-full  flex flex-col justify-center items-center">
          <div data-aos="fade-up">
            <Titulo titulo="Nossa Cotação" />
          </div>
          <div className="flex mt-10">
            <div
              data-aos="fade-right"
              className="w-2/4 lg:flex justify-center items-center hidden "
            >
              <Lottie options={defaultOptions} height={450} width={450} />
            </div>
            <div className="card glass w-96">

              <div className="card-body">
              <h2 className="card-title">Cotação - A cada 1 Real jogado</h2>
                <table className="table-zebra" color="white">
                  <thead>
                    <tr>
                      <th>LOTERIA</th><th>PREMIAÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>MILHAR</td><td>R$3.000,00</td>
                    </tr>
                    <tr>
                      <td>SUPER MILHAR</td><td>R$10.000,00</td>
                    </tr>
                    <tr>
                      <td>CENTENA</td><td>R$500,00</td>
                    </tr>
                    <tr>
                      <td>DEZENA</td><td>R$50,00</td>
                    </tr>
                    <tr>
                      <td>DUQUE DEZENA</td><td>R$200,00</td>
                    </tr>
                    <tr>
                      <td>TERNO DE DEZENA</td><td>R$3.000,00</td>
                    </tr>
                    <tr>
                      <td>GRUPO</td><td>R$15,00</td>
                    </tr>
                    <tr>
                      <td>DUPLA DE GRUPO</td><td>R$16,00</td>
                    </tr>
                    <tr>
                      <td>DUPLA DE GRUPO COLOCADO</td><td>R$160,00</td>
                    </tr>
                    <tr>
                      <td>TERNO DE GRUPO</td><td>R$100,00</td>
                    </tr>
                    <tr>
                      <td>TERNO DE GRUPO COLOCADO</td><td>R$160,00</td>
                    </tr>
                    <tr>
                      <td>LOTOQUINA</td>
                      <td>
                        5 - R$1.000,00<br/>
                        4 - R$ 100,00<br/>
                        3 - R$10,00<br/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex mt-10">
            <div className="w-full lg:w-3/4">
              <h1 className={`font-bold text-4xl mb-2 text-[${primaryColor}]`}>Sistema de Cotação dos Prêmios</h1>
              <p>Nosso sistema de cotação de prêmios é simples e transparente. </p>
              <p>Cada tipo de aposta tem uma cotação específica que determina o valor do prêmio. Por exemplo, se você apostar na milhar e acertar, você ganha R$ 3.000 para cada R$ 1 apostado. <br/>
                  Isso significa que, se você fizer uma aposta de R$ 5 na milhar e vencer, você receberá R$ 15.000.</p>
              <p>Assim, quanto maior a sua aposta, maior será o seu prêmio, sempre mantendo a proporção da cotação estabelecida. Aposte e descubra como é fácil ganhar com nossa <strong>loteria online!</strong></p>            
            </div>
            <div data-aos="fade-up" className="w-2/4  hidden lg:block">
              <Lottie options={defaultOptions2} height={400} width={400} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
