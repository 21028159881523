import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SobreImagem from '../../assets/images/business-woman-with-bills.png'
import { primaryColor } from "../../helpers/colors";

export default function Sobre() {

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="sobre" className="my-10">
      <div className="w-full lg:p-0 py-20 px-20 gap-10 flex justify-center items-center ">
        <div data-aos="fade-up" className="w-2/4 hidden lg:flex  justify-center items-center ">
          <img src={SobreImagem} alt="Sobre" style={{width:"60%"}}  />
        </div>
        <div data-aos="fade-up" className="w-full mt-10 lg:mt-0 lg:w-2/4">
          <div className="w-full lg:w-3/4">
            <h1 className={`font-bold text-4xl mb-2 text-[${primaryColor}]`}>Jogue com Facilidade, Rapidez e Confiabilidade</h1>
            <p className="text-justify mt-10 text-gray-600 text-lg">
            No nosso site de loteria online, você terá uma experiência fácil, rápida e confiável. O processo de cadastro é simples e leva apenas alguns minutos. Além disso, garantimos a transparência e a segurança em todos os nossos jogos, para que você possa jogar com total confiança. Quando você ganha, o pagamento é creditado diretamente na sua conta via PIX, garantindo que você receba seus prêmios de forma rápida e segura. Venha jogar e descubra a praticidade e a confiança do nosso serviço!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
