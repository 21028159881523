import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Inicio from "../components/Inicio";
import Preco from "../components/Preco";
import Recurso from "../components/Recurso";
import Sobre from "../components/Sobre";
import "./Landing.css";
import Novidades from "../components/Novidades";
import Download from "../components/Download";
import Newsletter from "../components/Newsletter";
import Demonstrativo from "../components/Demonstrativo";
import Resultado from "../components/Resultados";
export default function Landing() {
  useEffect(() => {
    document.title = `Gentil - Fácil rapido e confiável X anos no mercado`;
  }, []);
  return (
    <div className="bg-white">
      <div className="text-white  inicioOnda ">
        <Header />
        <Inicio />
      </div>
      <Resultado/>
      <Sobre />
      <Recurso />      
      <Novidades />
      <Newsletter />
      {/* <Demonstrativo /> */}
      <Download />

      <Footer />
    </div>
  );
}
