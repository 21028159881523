import React, { useEffect } from "react";
import InicioImagem from "../../assets/images/online-game-concept.png";
import Botao from "../Botao";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Inicio() {


  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="inicio">
      <div>

        <div
          className="flex inicioOnda items-start p-10 pr-20 pl-20  m-0 lg:min-h-[697px] min-h-[900px]"
        >
          <div className="flex flex-col w-full lg:w-2/4 justify-center items-center mt-10">
            <div
              data-aos="fade-up"
              className="w-full lg:w-3/4 flex flex-col justify-center items-start"
            >
              <h1 className="font-bold text-5xl mb-4">
                Junte-se a nós e comece sua jornada rumo à sorte hoje mesmo!
              </h1>
              <p className="text-justify text-sm mt-4 mb-4">
                Seja bem-vindo ao nosso site de loteria online, onde a diversão e a emoção estão sempre ao seu alcance! Junte-se a nós e descubra como é fácil, rápido e seguro participar dos nossos jogos.
              </p>
              <a href="#" target="_blank">
                <Botao
                  backgroundColor="bg-white"
                  color="text-[#0E65E5]"
                  hoverColor="hover:bg-transparent"
                  hoverText="hover:text-white"
                  text="Cadastre-se"
                  width="w-40"
				  onClick="javascript:window.location.href='https://online.noposte.app'"
                />
              </a>
            </div>
          </div>
          <div className="lg:flex hidden flex-col w-2/4 justify-center items-center ">
            <img src={InicioImagem} alt="inicio" className="relative w-[80%] " />
          </div>
        </div>
      </div>
    </div>

  );
}
