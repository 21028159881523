import React from 'react'
import Botao from '../Botao'
import { ToastContainer, toast } from 'react-toastify';

export default function Download() {
    return (
        <div className='lg:m-20 mx-10 my-40 bg-white flex justify-center'>
            <ToastContainer />

            <div data-aos="fade-up" className='bg-primary  w-full lg:w-3/4 flex flex-col justify-center items-center text-white text-center rounded-lg px-20 py-16'>
                <h1 className='font-bold text-5xl'>Junte-se a nós !</h1>
                <p className='text-sm mt-4 w-full lg:w-2/4'>Seja bem-vindo ao nosso site de <strong>loteria online,</strong> 
                onde a diversão e a emoção estão sempre ao seu alcance! Junte-se a nós e descubra como é fácil, rápido e seguro participar dos nossos jogos. 
                Com um processo de cadastro simples, você pode começar a jogar em poucos minutos. Nossa plataforma oferece transparência e confiabilidade em todas as etapas, garantindo que você se sinta seguro ao fazer suas apostas.
Aqui, cada jogada pode transformar sua vida. 
Além disso, com nosso sistema de pagamento via PIX, você recebe seus prêmios diretamente na sua conta bancária de forma rápida e segura. Não perca mais tempo, venha fazer parte da nossa comunidade de jogadores e aumente suas chances de ganhar grandes prêmios. Junte-se a nós e comece sua jornada rumo à sorte hoje mesmo!</p>

            </div>

        </div>
    )
}
