import axios from "axios";
import {formatarDataUSA} from  '../../helpers/date';
import { useEffect, useState } from "react";
import {primaryColor} from '../../helpers/colors'

export default function Resultado() {
    const [resultados, setResultados] = useState([]);
    // const [loterias, setLoterias] = useState([]);

    useEffect(() => {
        let data = formatarDataUSA(new Date());
        axios.get(`http://130.185.238.160/api/Resultados/get-resultados-dia?data=${data}`).then((res) => {
            setResultados(res.data)            
        });
    }, []);


    console.log(resultados);
    return (
        <div id="resultados" className="my-10">
            <h1 className={`font-bold text-4xl mb-2 text-[${primaryColor}]`}>Resultado</h1>
            <div className="grid grid-cols-3 gap-3 p-20">  
                {resultados.map((item) => (
                <div className="card glass">
                    <div className="card-body">
                        <h2 className="card-title">{item.extracao.nome}</h2>
                        <table>
                            <tr><td>#</td><td>Nro</td><td>Grupo</td></tr>
                            <tr><td>1</td><td>{item.primeiro}</td><td>{item.grupo1}</td></tr>
                            <tr><td>2</td><td>{item.segundo}</td><td>{item.grupo2}</td></tr>
                            <tr><td>3</td><td>{item.terceiro}</td><td>{item.grupo3}</td></tr>
                            <tr><td>4</td><td>{item.quarto}</td><td>{item.grupo4}</td></tr>
                            <tr><td>5</td><td>{item.quinto}</td><td>{item.grupo5}</td></tr>
                            <tr><td>6</td><td>{item.sexto}</td><td>{item.grupo6}</td></tr>
                            <tr><td>6</td><td>{item.setim}</td><td>{item.grupo7}</td></tr>                            
                        </table>
                    </div>
                </div>
                ))}
            </div>
        </div>
)

}