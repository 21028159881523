import React from 'react'
import Titulo from '../Titulo'
import ImagemNovidades from '../../assets/images/novidades.png'

export default function Novidades() {


    return (
        <div id="novidades" className=' lg:px-20 py-20 px-10 '>
            <div data-aos="fade-up">
                <Titulo titulo="Dúvidas" />
            </div>
            <div className='flex w-full justify-center items-start gap-4  mt-20'>
                <div data-aos="fade-up" className=' lg:w-2/4 hidden lg:flex justify-center
                     items-center'>
                    <div className='bg-[#F7F1FE] p-20 rounded-lg w-[600px] '>
                        <img src={ImagemNovidades} width={600} alt='novidades-imagem' />

                    </div>
                </div>
                <div data-aos="fade-up" className="join  join-vertical rounded-none w-full lg:w-2/4">
                    <div className="collapse collapse-arrow join-item border-t border-b border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            Como faço para me cadastrar no site de loteria online?
                        </div>
                        <div className="collapse-content">
                            <p>Para se cadastrar, clique no botão "Cadastre-se" na página inicial. Preencha o formulário com suas informações pessoais, como nome, e-mail e senha. Após enviar o formulário, você receberá um e-mail de confirmação. Clique no link do e-mail para ativar sua conta e começar a jogar.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-t border-b border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            Como funciona o sistema de cotação dos prêmios?
                        </div>
                        <div className="collapse-content">
                            <p>Nosso sistema de cotação de prêmios é baseado no valor da aposta. Por exemplo, se você apostar na milhar e acertar, você ganha R$ 3.000 para cada R$ 1 apostado. Isso significa que, se você apostar R$ 10, o prêmio será de R$ 30.000. A cotação para cada tipo de aposta é claramente especificada antes de você confirmar sua aposta.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-t  border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            Quais são as formas de pagamento disponíveis?
                        </div>
                        <div className="collapse-content">
                            <p>Oferecemos o PIX como forma de pagamento. O pagamento via PIX é instantâneo, garantindo que seu saldo seja atualizado rapidamente para que você possa começar a jogar sem demora.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-t  border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            Como recebo meu prêmio após ganhar?
                        </div>
                        <div className="collapse-content">
                            <p>Após ganhar, seu prêmio será automaticamente creditado na sua conta do site. Você pode solicitar a retirada do prêmio a qualquer momento, e ele será transferido diretamente para a sua conta bancária via PIX. O processo é rápido e seguro, garantindo que você receba seus ganhos sem complicações.</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    )
}
